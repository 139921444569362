<template>
  <div class="page-container">
    <section-title>
      <div class="title-content">
        <img class="note-icon" src="@/assets/images/register/icon_ts.png" alt="">
        <span>注意事项</span>
      </div>
    </section-title>
    <notice>请仔细填写"理赔资料"中的信息，确保银行卡、姓名、身份证号为同一人,信息不一致将无法理赔。如有疑问请联系客服: <a style="text-decoration: underline" href="tel:02886717517">02886717517</a></notice>
    <section-title title="理赔资料填写"></section-title>
    <cell-input title="领款人姓名" placeholder="请填写领款人姓名" v-model="form.payeeName" @change="identityVerification"></cell-input>
    <cell-input title="联系电话" placeholder="请填写联系电话" v-model="form.telephone" type="tel"></cell-input>
    <cell-input title="开户银行名" placeholder="请填写开户银行名" v-model="form.bankCodeOrName"></cell-input>
    <cell-input title="银行卡号" placeholder="请填写卡号" v-model="form.bankAccount" type="number"></cell-input>
    <cell-input title="身份证号" placeholder="请填写身份证号" v-model="form.identifyNumber" type="id-card" @change="identityVerification"></cell-input>

    <van-cell class="理赔身份验证" title="理赔身份验证">
      <span v-if="isSameIdentity">理赔身份一致</span>
      <span v-if="!isSameIdentity&&isSignContract" class="cell-btn" @click="signContract(1)">修改转让书信息</span>
      <span v-if="!isSameIdentity&&!isSignContract" class="cell-btn" @click="signContract(0)">签署权益转让书</span>
    </van-cell>

    <section-title title="维修资料填写"></section-title>
    <cell-input title="IMEI号" placeholder="请填写IMEI号" v-model="form.imei"></cell-input>
    <money title="维修金额" placeholder="请填写维修金额" v-model="form.money"></money>
    <field-piker
        label='维修类型'
        placeholder="请选择维修类型"
        title='请选择维修类型'
        :columns='repairTypes'
        v-model="form.finish_type"
    >
    </field-piker>
    <field-piker
        label='维修方式'
        placeholder="请选择类型选择"
        title='维修类型选择'
        :columns='repairMethods'
        v-model="form.finish_mode"
    >
    </field-piker>
    <section-title title="维修完成照"></section-title>
    <pic-uploader @change="url=>form.tem_img=url" :src="form.tem_img"
                  :example="require('@/assets/images/examplePic/example9.png')"></pic-uploader>
    <section-title title="工单照片"></section-title>
    <pic-uploader @change="url=>form.tem_work=url" :src="form.tem_work"
                  :example="require('@/assets/images/examplePic/example10.png')"></pic-uploader>
    <section-title title="发票照片"></section-title>
    <pic-uploader @change="url=>form.tem_invoice=url" :src="form.tem_invoice"
                  :example="require('@/assets/images/examplePic/example16.png')"></pic-uploader>


    <cell-input title="	备注" placeholder="请填写备注" v-model="form.remarks"></cell-input>

    <my-button class="confirm-btn" :type="isDone?'primary':'default'" :disabled="!isDone" @click="confirm">提交申请
    </my-button>

  </div>
</template>

<script>
import {addReimData, getReimData} from "@/api/claimInfo";
import {getFinishMode, getFinishType} from "@/api/register";

export default {
  name: "index",
  props: {
    id: [String, Number],
  },
  data() {
    return {
      form: {
        claims_id: '',
        payeeName: '',
        telephone: '',
        bankCodeOrName: '',
        bankAccount: '',
        identifyNumber: '',
        imei: '',
        money: '',
        finish_type: '',
        finish_mode: '',
        tem_img: '',
        tem_work: '',
        tem_invoice: '',
        remarks: '',
        sign1: '',
        sign2: '',
      },
      claims_sn: '',
      isDone: false,
      repairTypes: [],
      repairMethods: [],
      claimInfo: {}
    }
  },
  computed: {
    isSameIdentity() {
      return this.claimInfo.name===this.form.payeeName&&this.claimInfo.id_card===this.form.identifyNumber
    },
    isSignContract() {
      return this.form.sign1&&this.form.sign2
    }
  },
  activated() {
    if (sessionStorage.getItem('signature1')) {
      this.form.sign1=sessionStorage.getItem('signature1')
      // sessionStorage.removeItem('signature1')
    }
    if (sessionStorage.getItem('signature2')) {
      this.form.sign2=sessionStorage.getItem('signature2')
      // sessionStorage.removeItem('signature2')
    }
  },
  mounted() {
    this.form.claims_id=this.id

    getReimData({
      id: this.id,
    }).then(res => {
      if (res && res.data) {
        this.claimInfo=res.data
        this.form.imei=res.data.imei
        this.claims_sn=res.data.claims_sn
      }
    }).catch(err => {
      console.log(err)
    })

    getFinishType().then(res => {
      this.repairTypes = res.data
    })
    getFinishMode().then(res => {
      this.repairMethods = res.data
    })
  },
  watch: {
    form: {
      handler(form) {
        this.checkForm(form)
      },
      deep: true,
    }
  },
  methods: {
    checkForm(form) {
      let isDone = true
      console.log(`%c -----------------------`,'color:red')
      Object.keys(form).forEach(key => {
        if (key !== 'remarks'&&key!=='sign1'&&key!=='sign2') {
          if (!form[key]) {
            console.log(key)
            isDone = false;
          }
        }
      })
      if (!this.isSameIdentity && !this.isSignContract) {
        if (!form.sign1 || !form.sign2) {
          console.log('sign')
          isDone = false;
        }
      }
      this.isDone = isDone
    },
    identityVerification() {
      console.log()
    },
    signContract() {
      if (!this.form.payeeName) {
        this.$toast('请先填写受让人姓名')
        return;
      }
      if (!this.form.telephone) {
        this.$toast('请先填写受让人联系电话')
        return;
      }
      if (!this.form.identifyNumber) {
        this.$toast('请先填写受让人身份证号')
      }
      this.$router.push({
        name: 'contract',
        query:{
          policyNumber:this.claimInfo.policyNo||'PJTE202251010000000037',
          model:this.claimInfo.model_name,
          transferor:this.claimInfo.name,
          assignee:this.form.payeeName,
          assigneePhone:this.form.telephone,
          damageTime:this.claimInfo.add_date,
        }
      });
    },
    confirm() {
      addReimData(this.form).then(res => {
        if (res && res.code) {
          this.$myDialog({
            type: 'success',
            title: res.msg || '提交成功',
            confirmButtonText: '确定',
            beforeClose: (action, done) => {
              done()
              // if (action === 'confirm') {
              //   done();
              //
              //   this.$router.push('/RepairInfoDetail/' + res.data.id);
              // } else {
              //   done()
              // }
            }
          });
        } else {
          this.$toast(res.msg || '审核申请提交失败');
        }
      }).catch(err => {
        this.$toast(err && err.msg || '审核申请提交失败');
      });
      //   let {userInfo} = this.$store.state
      //   setRepairFinish({
      //     // openid: userInfo.openid,
      //     claims_id: this.id,
      //     imei: this.form.imei,
      //     money: this.form.money,
      //     finish_type: this.form.finish_type,
      //     finish_mode: this.form.finish_mode,
      //     tem_img: this.form.img,
      //     tem_work: this.form.work,
      //     remarks: this.form.remarks
      //   },this.openId).then(res => {
      //     if (res && res.code) {
      //       this.$myDialog({
      //         type: 'success',
      //         title: res.msg || '提交成功',
      //         confirmButtonText: '查看详情',
      //         beforeClose:(action, done)=>{
      //           if (action === 'confirm') {
      //             done();
      //
      //             this.$router.push('/RepairInfoDetail/' + res.data.id);
      //           } else {
      //             done()
      //           }
      //         }
      //       });
      //     } else {
      //       this.$toast(res.msg || '审核申请提交失败');
      //     }
      //   }).catch(err => {
      //     this.$toast(err && err.msg || '审核申请提交失败');
      //   })
    }
  }
}
</script>

<style scoped lang="less">

.title {
  padding-left: 24px;
  margin: 40px 0 20px;
  font-weight: 800;
  font-size: 32px;
  color: #333333;
}

.cell-input {
  text-align: right;
  background: transparent;
}

.cell-input.money {
  color: @red;
}

.img-note {
  width: calc(100vw - 60px);
  min-height: 88px;
  background: #F6F8FE;
  border-radius: 16px;
  font-size: 28px;
  color: #333333;
  padding: 26px;
  margin-bottom: 30px;
  text-align: justify;
}

.confirm-btn {
  width: calc(100vw - 150px);
  margin: 30px auto 0;
}

::v-deep .imei input {
  background: #fff;
  color: #999;
}

.note-icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.cell-btn {
  display: inline-block;
  background: #577BB5;
  height: 60px;
  line-height: 60px;
  padding: 0 10px;
  border-radius: 4px;
  color: #fff;
}
</style>
