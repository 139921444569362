import request from "@/utils/http";

export function getReimData(params) {
    return request({
        url: 'Lifeinsurance/getReimData',
        method: 'get',
        params
    })
}
export function addReimData(params = {}) {
    return request({
        url: 'Lifeinsurance/addReimData',
        method: 'post',
        data: params
    })
}
